import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal } from "@mantine/core";
import styles from "./PageHeader.module.scss";
import Button from "../common/Button";
import Fade from "react-reveal/Fade";

import loadable from "@loadable/component";
import { PlayIcon } from "@heroicons/react/20/solid";
import { Link } from "gatsby";

const AnimationComponent = loadable(() =>
  import("../animations/AnimationComponent.js")
);

const PageHeader = () => {
  const [open, setOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    handleResize();

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const videoRef = useRef();

  const handleFullscreenClick = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      // Firefox
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      // IE/Edge
      videoRef.current.msRequestFullscreen();
    } else if (videoRef.current.webkitEnterFullscreen) {
      // iOS Safari
      videoRef.current.webkitEnterFullscreen();
    }
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          {/* <div className={styles.heading}>
            <div>
              <span className={styles.tag}>Welcome to the</span>
              <Fade up duration={1000} delay={0} distance="30px" cascade>
                <h1 className={styles.title}>Universe of my Art</h1>
              </Fade>
              <div className={styles.buttonContainer}>
                <Link to="/collections/popular/">
                  <div className={styles.button}>Popular Artworks</div>
                </Link>
                <Link to="/collections/newest/">
                  <div className={styles.button}>New Artworks</div>
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default PageHeader;
