import React, { useState } from 'react'
import styles from './PricingPlans.module.scss'
import Button from '../common/Button'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

const pricingPlans = [
  {
    title: 'Starter',
    price: 'Free',
    subPrice: 'Forever',
    description:
      'Start creating maps and perform geospatial analysis with our free plan',
    features: [
      'Up to 3 projects',
      'Unlimited collaborators',
      '500MB file storage',
      'Private & public projects',
      'Image & PDF exports',
      'Upload any geo format',
      'Geospatial analysis',
      'Embed maps',
      'Comments'
    ]
  },
  {
    title: 'Professional',
    price: '$39',
    subPrice: 'per user / month',
    description:
      'For professionals that need to create maps and share them with clients',
    features: [
      'Unlimited projects',
      '10GB file storage',
      'Embed without logo',
      'Password-protected maps',
      'Professional exports'
    ]
  },
  {
    title: 'Organization',
    price: '$89',
    subPrice: 'per user / month',
    description:
      'For teams and organizations that need to collaborate on maps and data',
    features: [
      '50GB file storage',
      'Organization-wide datasets',
      'Embed without logo',
      'Professional PDF exports',
      'Version history (2024)',
      'API access (2024)'
    ]
  },
  {
    title: 'Enterprise',
    price: 'Contact us',
    subPrice: 'Tailored to your needs',
    description:
      'Manage the complexities of scaling geospatial databases and platform needs',
    features: [
      'Unlimited file storage',
      'Training & onboarding',
      'Guest access controls',
      'Dedicated success manager',
      'Custom datasets',
      'Branded login page',
      'Early access to features'
    ]
  }
]

const PricingPlans = () => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <h1>Pricing</h1>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={`${styles.centerPlan} ${styles.sky}`}>
            <h3>Free for non-enterprise customers!</h3>
            <p>
              We’re thrilled to announce that during our beta phase, Atlas is
              completely free for all non-enterprise users. Yes, you read that
              right—zero cost, unlimited possibilities!
            </p>
          </div>
          <div className={styles.layout}>
            {pricingPlans.map((plan, index) => (
              <div
                className={`${styles.plan} ${plan.title === 'Enterprise' &&
                  styles.dark}`}
              >
                <div className={styles.upperContent}>
                  <h3>
                    {plan.title}
                    {(plan.title === 'Professional' ||
                      plan.title === 'Organization') && (
                      <span className={styles.betaTag}>Beta</span>
                    )}
                  </h3>
                  <p>{plan.description}</p>
                  <h2>{plan.price}</h2>
                  <p className={styles.subPrice}>{plan.subPrice}</p>
                  <div>
                    {plan.title !== 'Enterprise' ? (
                      <Button href="https://app.atlas.co/login" fullWidth>
                        {'Choose ' + plan.title}
                      </Button>
                    ) : (
                      <Button href="/contact" variant="inverse" fullWidth>
                        Book a demo
                      </Button>
                    )}
                  </div>
                </div>
                <div className={styles.lowerContent}>
                  <p>
                    {plan.title === 'Starter'
                      ? 'Features:'
                      : `Everything in ${
                          pricingPlans[index - 1].title
                        }, plus...`}
                  </p>
                  <ul>
                    {plan.features.map(feat => (
                      <ListItem text={feat} />
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.centerPlan}>
            <h2>Agency Accounts</h2>
            <p>
              Are you an agency or service provider? Join our partner program to
              gain access to a free service partner account, exclusive features,
              and commission opportunities.
            </p>
            <div>
              <Button href="/partnerships">Apply as Partner</Button>
              <Button href="/contact" variant="secondary">
                Book a demo
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ListItem = ({ text }) => {
  return (
    <li className={styles.listItem}>
      <CheckCircleIcon /> {text}
    </li>
  )
}

export default PricingPlans
