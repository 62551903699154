import React, { useState, useEffect, useRef } from 'react'
import styles from './ProductOverview.module.scss'
import Button from '../common/Button'
import 'intersection-observer'

import loadable from '@loadable/component'

const AnimationComponent = loadable(() =>
  import('../animations/AnimationComponent.js')
)

const tabs = [
  {
    title: 'Make interactive maps',
    content:
      'Build interactive maps that explains your data and encourage users to Explore. Map your own data and gain perspective by adding location-based data included in Atlas.'
  },
  {
    title: 'Co-create in one space',
    content:
      'Work effectivley across your organization to build and use maps. No more massive downloads, emailing maps or lost files. Just all your maps organized in one place.'
  },
  {
    title: 'Powerful tools everyone can use',
    content:
      'Understand your data in the context of location by using intuitive analysis. Reveal relationships, identify prime locations, use optimal routes, and analyze patterns to make predictions.'
  },
  {
    title: 'Simply send a link to share any map',
    content:
      'Share your map with specific groups or everyone. Create web apps with your maps for a focused, interactive experience. Then integrate the apps you built into your digital presence.'
  },
  {
    title: 'Connected to your data',
    content:
      'Bring your own data into a powerful system that geoenables, hosts, and scales. Update and add to your data without disrupting the maps and apps that use the data. '
  }
]

const ProductOverview = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [borderWidth, setBorderWidth] = useState(0)
  const slideDuration = 5000 // 5 seconds

  const tabIntervalRef = useRef(null)

  useEffect(() => {
    tabIntervalRef.current = setInterval(() => {
      setActiveTab(prevTab => (prevTab + 1) % tabs.length)
      setBorderWidth(0)
      setTimeout(() => {
        setBorderWidth('100%')
      }, 10)
    }, slideDuration)

    return () => {
      clearInterval(tabIntervalRef.current)
    }
  }, [tabs])

  const handleTabClick = index => {
    clearInterval(tabIntervalRef.current)
    setActiveTab(index)
    setBorderWidth(0)
    setTimeout(() => {
      setBorderWidth('100%')
    }, 10)

    tabIntervalRef.current = setInterval(() => {
      setActiveTab(prevTab => (prevTab + 1) % tabs.length)
      setBorderWidth(0)
      setTimeout(() => {
        setBorderWidth('100%')
      }, 10)
    }, slideDuration)
  }

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    // Initial check
    handleResize()

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>
            The Most Powerful GIS <br /> Software in the Browser
          </h2>
          <p>
            Connected to your data. Delightful to share. Collaborate spatially
            without installing software.
          </p>
          <div style={{ marginTop: '1rem' }}>
            <Button href="https://app.atlas.co/login">Start for free</Button>

            <Button href="/contact" variant="secondary">
              Book a demo
            </Button>
          </div>
        </div>
        <div className={`${styles.gridLayout} ${styles.firstRow}`}>
          <div className={styles.gridItem}>
            <AnimationComponent selector="uploading" />
          </div>
          <div className={styles.gridItem}>
            <AnimationComponent selector="locations" />
          </div>
        </div>
        <div className={`${styles.gridLayout} ${styles.secondRow}`}>
          <div
            className={`${styles.gridItem} ${!isMobile &&
              styles.large} ${isMobile && styles.small}`}
          >
            <AnimationComponent selector="screen" />
          </div>
          <div className={`${styles.gridItem} ${styles.large}`}>
            <AnimationComponent selector="share" />
          </div>
        </div>
        {/* <div className={styles.header}>
          <h2>
            The Most Powerful GIS <br /> Software in the Browser
          </h2>
          <p>
            Connected to your data. Delightful to share. Atlas is how teams work
            with geospatial data and share their results.
          </p>
          <div style={{ marginTop: '1rem' }}>
            <Button href="https://app.atlas.co/login">Start for free</Button>

            <Button href="/contact" variant="secondary">
              Book a demo
            </Button>
          </div>
        </div> */}
        {/* <div className={styles.grid}>
          <div className={styles.tabContainer}>
            <div>
              {tabs.map((tab, index) => (
                <div
                  className={`${styles.tab} ${activeTab === index + 1 &&
                    styles.nextActive}`}
                >
                  <div
                    className={`${styles.tabHeader} ${activeTab === index &&
                      styles.active}`}
                    onClick={() => activeTab !== index && handleTabClick(index)}
                  >
                    <h3>{tab.title}</h3>
                  </div>
                  <div
                    className={`${styles.tabContent} ${activeTab === index &&
                      styles.active}`}
                  >
                    {tab.content}
                  </div>
                  {activeTab === index && (
                    <div
                      className={styles.bottomBorder}
                      style={{ width: borderWidth }}
                    ></div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img
              src={`/images/product-overview-0${activeTab + 1}.svg`}
              alt=""
            />
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default ProductOverview
