import React, { useState, useEffect, useRef } from 'react'
import styles from './CommunityMaps.module.scss'
import Button from '../common/Button'
import 'intersection-observer'
import { formatDateToDaysAgo } from '../../utils/format'
import { Link } from 'gatsby'

const tabs = [
  {
    title: 'Connected to your data',
    content:
      'Bring your own data into a powerful system that geoenebles, hosts, and scales. Update and add to your data without disrupting the maps and apps that use the data. '
  },
  {
    title: 'Powerful tools everyone can use',
    content:
      'Understand your data in the context of location by using intuitive analysis. Reveal relationships, identify prime locations, use optimal routes, and analyze patterns to make predictions.'
  },
  {
    title: 'Simply send a link to share any map',
    content:
      'Share your map with specific groups or everyone. Create web apps with your maps for a focused, interactive experience. Then integrate the apps you built into your digital presence.'
  },
  {
    title: 'Co-create in one space',
    content:
      'Work effectivley across your organization to build and use maps. No more massive downloads, emailing maps or lost files. Just all your maps organized in one place.'
  },
  {
    title: 'Make interactive maps',
    content:
      'Build interactive maps that explains your data and encourage users to Explore. Map your own data and gain perspective by adding location-based data included in Atlas.'
  }
]

const CommunityMaps = ({ maps }) => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.tag}>Community</p>
          <h2>
            Explore what others <br /> have made with Atlas
          </h2>
          <p>
            Connected to your data. Delightful to share. Atlas is how teams work
            with geospatial data and share their results.
          </p>
        </div>
        <div className={styles.grid}>
          <div className={styles.imageContainer}>
            <img src="/images/community-maps.png" alt="Community Maps" />
          </div>
          <div>
            <h3>Featured Maps</h3>
            <div className={styles.gridColumn}>
              {maps[0] && (
                <GridItem
                  title={maps[0].frontmatter.title}
                  link={maps[0].frontmatter.link}
                  tag={maps[0].frontmatter.tag}
                  image={maps[0].frontmatter.featuredImage}
                  featured={true}
                  created={maps[0].frontmatter.created}
                  creator={maps[0].frontmatter.creator}
                />
              )}
              {maps[1] && (
                <GridItem
                  title={maps[1].frontmatter.title}
                  link={maps[1].frontmatter.link}
                  tag={maps[1].frontmatter.tag}
                  image={maps[1].frontmatter.featuredImage}
                  featured={true}
                  created={maps[1].frontmatter.created}
                  creator={maps[1].frontmatter.creator}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const GridItem = ({
  id,
  title,
  link,
  tag,
  image,
  featured,
  trending,
  newest,
  created,
  creator
}) => {
  const [hover, setHover] = useState(false)
  return (
    <a href={link} target="_blank">
      <div
        className={styles.postCard}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {featured && (
          <div className={styles.featured}>
            <div className={styles.ribbon} />
          </div>
        )}
        <div className={`${styles.cardImage} ${hover && styles.hovered}`}>
          <img src={image} alt={title} />
        </div>
        {/* <div className={styles.cardTag}>{tag}</div> */}
        <div className={styles.cardContent}>
          <h3>{title}</h3>
        </div>
      </div>
    </a>
  )
}

export default CommunityMaps
