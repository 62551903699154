import React, { useState, useEffect, useRef } from 'react'
import styles from './UserSection.module.scss'
import Fade from 'react-reveal/Fade'

import loadable from '@loadable/component'

const AnimationComponent = loadable(() =>
  import('../animations/AnimationComponent.js')
)

const UserSection = () => {
  const [currentUserGroup, setCurrentUserGroup] = useState(0)
  const userGroups = [
    'planner',
    'analyst',
    'manager',
    'cartographer',
    'teacher',
    'student'
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUserGroup(prevTab => (prevTab + 1) % userGroups.length)
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const getColor = () => {
    switch (currentUserGroup) {
      case 0:
        return '#FF7D6A'
      case 1:
        return '#FFC700'
      case 2:
        return '#BAD4FF'
      case 3:
        return '#FFBAF7'
      case 4:
        return '#7BF1A8'
      case 5:
        return '#CDA3FF'
      default:
        return '#BAD4FF'
    }
  }

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <AnimationComponent selector="areaPlanning" />
          {/* <img src="/images/use-cases.png" alt="Use cases" /> */}
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <h2>
              For every{' '}
              <Fade spy={currentUserGroup} duration={500}>
                <span
                  className={styles.userGroup}
                  style={{ backgroundColor: getColor() }}
                >
                  {userGroups[currentUserGroup]}
                </span>
              </Fade>
              ,
              <br /> make maps with ease
            </h2>
            <p className={styles.subHeading}>
              Build interactive maps that explains your data and encourage users
              to explore.
            </p>
          </div>
          <div className={styles.grid}>
            <div className={styles.item}>
              <img src="/icons/analyze-data.svg" alt="Analyze data" />
              <h4>Analyze data</h4>
              <p>
                Understand your data in the context of location using intuitive
                analysis tools
              </p>
            </div>
            <div className={styles.item}>
              <img src="/icons/visualize-trends.svg" alt="Visualize trends" />
              <h4>Visualize trends</h4>
              <p>Create maps that uncover spatial patterns and trends</p>
            </div>
            <div className={styles.item}>
              <img
                src="/icons/foster-collaboration.svg"
                alt="Foster collaboration"
              />
              <h4>Foster collaboration</h4>
              <p>
                Facilitate teamwork and information sharing among GIS
                professionals, stakeholders and the community
              </p>
            </div>
            <div className={styles.item}>
              <img
                src="/icons/engage-stakeholders.svg"
                alt="Engage stakeholders"
              />
              <h4>Engage stakeholders</h4>
              <p>
                Present plans and proposals in an easily understandable and
                accessible format
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserSection
