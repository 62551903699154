import React, { useEffect } from "react";
import PageHeader from "../components/home/PageHeader";
import Layout from "../components/common/Layout";
import Fade from "react-reveal/Fade";
import ProductOverview from "../components/home/ProductOverview";
import UserSection from "../components/home/UserSection";
import FeaturesSection from "../components/home/FeaturesSection";
import CommunityMaps from "../components/home/CommunityMaps";
import FAQ from "../components/common/FAQ";
import BestSellers from "../components/home/BestSellers";
import CTA from "../components/common/CTA";
import PricingPlans from "../components/pricing/PricingPlans";
import MeetTheArtist from "../components/singlePost/MeetTheArtist";
import SecuredPackaging from "../components/singlePost/SecuredPackaging";

const HomePage = ({ data }) => {
  return (
    <Layout
      meta={{
        description:
          "Museum-quality posters made on thick matte paper. Add a wonderful accent to your room and office with these posters that are sure to brighten any environment.",
        title: "LWE Art - Paintings, Posters & Art Prints",
        type: "website",
      }}
      title={"LWE Art - Paintings, Posters & Art Prints"}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader />
        </Fade>

        {/* <Fade up duration={1000} delay={0} distance="30px">
          <FeaturesSection />
        </Fade> */}
        <Fade up duration={1000} delay={0} distance="30px">
          <BestSellers artworks={data.artworks.nodes} />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <MeetTheArtist />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <SecuredPackaging />
        </Fade>
        {/* <Fade up duration={1000} delay={0} distance="30px">
          <FAQ />
        </Fade> */}
      </main>
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    artworks: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/artworks/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          type
          soldOut
          defaultSize
          images
          prices
          discount
          meta {
            title
            description
          }
        }
        fields {
          slug
        }
      }
    }
  }
`;
